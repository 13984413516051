import React, { useState, useEffect } from "react"

import { fetchProducts } from "../../api/products"
import { SORT_BY_ITEMS } from "../../utils/productsHelper"

import ProductAttributeMultiSelectFilter from "../../components/UI/Filters/ProductAttributeMultiSelectFilter"
import ProductAttributeColorFilter from "../../components/UI/Filters/ProductAttributeColorFilter"
import ProductAttributeSizeFilter from "../../components/UI/Filters/ProductAttributeSizeFilter"
import ProductPriceFilter from "../../components/UI/Filters/ProductPriceFilter"
import ProductSubcategoriesFilter from "../../components/UI/Filters/ProductSubcategoriesFilter"
import { Form } from "react-bootstrap"
import { moveElementById } from "../../components/AcfPageBlocks/ProductBrandFilter/utils"
const ProductFilters = ({ pageContext, productData, location }) => {
  const {
    attributes,
    colours,
    minPrice,
    maxPrice,
    subCategories,
    size,
    id,
    name,
    allModels,
  } = pageContext
  let orderedAttributes=attributes

  if(process.env.SITE_ID.startsWith('RG')) {
    orderedAttributes = moveElementById(attributes,'Season',0);
  }
  const [data, setData] = productData


  useEffect(() => {

    if (
      data.static === true &&
      location.search &&
      location.search.length > 0 &&
      data.initStaticView === false
    ) {
      let params = new URLSearchParams(location.search.substring(1))
      const onSale = params.get("onSale")
      const priceMaxParam = params.get("priceMax")
      const brand = params.get("brand")

      let filter = false
      const newFilters = { ...data.filters }
      if (onSale !== null && onSale !== "") {
        newFilters["onSale"] = onSale
        filter = true
      }
      if (priceMaxParam !== null && priceMaxParam !== "") {
        newFilters["priceMax"] = Number(priceMaxParam)
        filter = true
      }
      if (brand !== null && brand !== "") {
        newFilters[`${process.env.FILTER_BRAND_ATTRIBUTE_ID}`] = [brand]
        filter = true
      }

      //const newFilters = { ...data.filters, onSale: onSale, 4: [brand] }
      if (filter === true) {
        setData({
          ...data,
          static: false,
          filters: newFilters,
          fetching: true,
          page: 0,
          initStaticView: true,
        })
      }
    }
    // Update the document title using the browser API
    if (!data.static && data.fetching === true) {
      let filters = pageContext.brandPath
        ? { [process.env.FILTER_BRAND_ATTRIBUTE_ID]: name }
        : { category: id }

      let empty = true
      //forEach
      //fetchProducts(0, size,)
      for (let propertyName in data.filters) {
        let value = data.filters[propertyName]


        if (Array.isArray(value)) {
          if (value.length > 0) {
            value = value.join(",")
            filters[propertyName] = value
            empty = false
          }
        } else {
          filters[propertyName] = value
          empty = false
        }
      }

      if (empty && data.sortBy === 0) {
        setData({
          ...data,
          static: true,
          filters: {},
          products: null,
          fetching: false,
          page: 0,
          sortBy: 0,
        })
        // setModels([])
      } else {
        let sortByValue =
          data.sortBy !== 0 ? SORT_BY_ITEMS[data.sortBy].sortBy : undefined
        let sortDirectionValue =
          data.sortBy !== 0
            ? SORT_BY_ITEMS[data.sortBy].sortDirection
            : undefined

        fetchProducts(data.page, size, filters, sortByValue, sortDirectionValue)
          .then(response => {

            const excluded = process.env.EXCLUDED_PRODUCTS.split(",")
            response.data.data = response.data.data.filter(product => !excluded.includes(String(product.id)))


            let filteredModels = []
            response.data.data.map(product => filteredModels.push(product.models))



            setData({ ...data, products: response.data, fetching: false, allModels: [...new Set(filteredModels.flat())] })

          })
          .catch(error => {
            console.error(error)
            setData({
              ...data,
              static: true,
              filters: {},
              products: null,
              fetching: false,
              page: 0,
              sortBy: 0,
              allModels: [],
            })
          })
      }
    }
  })



  const [activeEventKey, setActiveEventKey] = useState(0)
  const filterMinPrice = data.static
    ? minPrice
    : data.filters.priceMin || minPrice

    const filterMaxPrice = data.static
    ? maxPrice
    : data.filters.priceMax || maxPrice

    const filteredModels = data.static
      ? allModels
      : data.allModels || allModels

  const handlePriceChange = values => {
    const newFilters = {
      ...data.filters,
      priceMin: values[0],
      priceMax: values[1],
    }
    setData({
      ...data,
      static: false,
      filters: newFilters,
      fetching: true,
      page: 0,
    })
  }

  const handleAttributeChange = (attributeId, values) => {
    const newFilters = { ...data.filters }
    newFilters[attributeId] = values

    setData({
      ...data,
      static: false,
      filters: newFilters,
      fetching: true,
      page: 0,

    })
  }

  const displayFilterInCategory = (currentCategoryName, categories) => {
    if (!categories) {
      return true
    }
    const categoriesNames = categories.split(",")
    if (categoriesNames.length === 0) {
      return true
    }
    return !categoriesNames.some(name => currentCategoryName === name)
  }

  return (
    <Form>
      {pageContext.categoryPath && (
        <ProductSubcategoriesFilter
          pageContext={pageContext}
          label={"Categories"}
          //activeEventKey={activeEventKey}
          //eventKey={200}
          subcategories={subCategories}
          onToggle={setActiveEventKey}
        />
      )}
      {!pageContext.categoryPath && (
        <>
          {/* TODO: THIS COMPONENT DOES NOT EXIST

            <ProductCategoryMultiselectFilter
              pageContext={pageContext}
              label={"Categories"}
              categories={categories}
              onToggle={setActiveEventKey}
            /> */}
        </>
      )}

      <ProductPriceFilter
        min={minPrice}
        max={maxPrice}
        currentValue={[filterMinPrice, filterMaxPrice]}
        label={"Price"}
        activeEventKey={activeEventKey}
        eventKey={0}
        onChange={handlePriceChange}
        onToggle={setActiveEventKey}
      />

      <ProductAttributeMultiSelectFilter
        name={"inStock"}
        label={"Stock"}
        options={["in stock only"]}
        values={
          data.filters["inStock"] && data.filters["inStock"].includes("true")
            ? ["in stock only"]
            : []
        }
        onToggle={setActiveEventKey}
        onChange={values =>
          handleAttributeChange(
            "inStock",
            values.includes("in stock only") ? ["true"] : values
          )
        }
      />

      <ProductAttributeMultiSelectFilter
        name={"onSale"}
        label={"On sale"}
        options={["on sale only"]}
        values={
          data.filters["onSale"] && data.filters["onSale"].includes("true")
            ? ["on sale only"]
            : []
        }
        onToggle={setActiveEventKey}
        onChange={values =>
          handleAttributeChange(
            "onSale",
            values.includes("on sale only") ? ["true"] : values
          )
        }
      />
      {orderedAttributes.map((item, i) => {
        return(
        <div key={i}>
          {process.env.FILTER_SEASON_ENABLE === "1" &&
            item.id === Number(process.env.FILTER_SEASON_ATTRIBUTE_ID) &&
            displayFilterInCategory(
                pageContext.name,
                process.env.FILTER_SEASON_EXCLUDED_FOR_CATEGORIES_NAMES
            ) && (
                <ProductAttributeMultiSelectFilter
                    name={item.name}
                    label={item.label}
                    options={item.options}
                    values={data.filters[item.id] || []}
                    onToggle={setActiveEventKey}
                    onChange={values => handleAttributeChange(item.id, values)}
                />
          )}
          {process.env.FILTER_COLOUR_ENABLE === "1" &&
            item.id === Number(process.env.FILTER_COLOUR_ATTRIBUTE_ID) &&
            displayFilterInCategory(
              pageContext.name,
              process.env.FILTER_COLOUR_EXCLUDED_FOR_CATEGORIES_NAMES
            ) && (
              <ProductAttributeColorFilter
                name={item.name}
                label={item.label}
                options={item.options}
                onToggle={setActiveEventKey}
                colours={colours}
                values={data.filters[item.id] || []}
                onChange={values => handleAttributeChange(item.id, values)}
              />
            )}
          {process.env.FILTER_SIZE_ENABLE === "1" &&
            item.id === Number(process.env.FILTER_SIZE_ATTRIBUTE_ID) &&
            displayFilterInCategory(
              pageContext.name,
              process.env.FILTER_SIZE_EXCLUDED_FOR_CATEGORIES_NAMES
            ) && item.options.length > 0 && (
              <ProductAttributeSizeFilter
                name={item.name}
                label={item.label}
                options={item.options}
                values={data.filters[item.id] || []}
                onToggle={setActiveEventKey}
                onChange={values => handleAttributeChange(item.id, values)}
              />
            )}


          {process.env.FILTER_BRAND_ENABLE === "1" &&
            item.id === Number(process.env.FILTER_BRAND_ATTRIBUTE_ID) &&
            displayFilterInCategory(
              pageContext.name,
              process.env.FILTER_BRAND_EXCLUDED_FOR_CATEGORIES_NAMES
            ) &&
            !pageContext.brandPath && (
              <ProductAttributeMultiSelectFilter
                name={item.name}
                label={item.label}
                options={item.options}
                values={data.filters[item.id] || []}
                onToggle={setActiveEventKey}
                onChange={values => handleAttributeChange(item.id, values)}
              />
            )}


          {filteredModels.length > 0 && process.env.FILTER_MODEL_ENABLE === "1" &&
            item.id === Number(process.env.FILTER_MODEL_ATTRIBUTE_ID) &&
            displayFilterInCategory(
              pageContext.name,
              process.env.FILTER_MODEL_EXCLUDED_FOR_CATEGORIES_NAMES
            ) && (
              <ProductAttributeMultiSelectFilter
                name={item.name}
                label={item.label}
                options={filteredModels}
                values={data.filters[item.id] || []}
                onToggle={setActiveEventKey}
                onChange={values => handleAttributeChange(item.id, values)}
              />
            )}




          {process.env.FILTER_ENGINE_SIZE_ENABLE === "1" &&
              item.id === Number(process.env.FILTER_ENGINE_SIZE_ATTRIBUTE_ID) &&
              displayFilterInCategory(
                  pageContext.name,
                  process.env.FILTER_MODEL_EXCLUDED_FOR_CATEGORIES_NAMES
              ) && (
                  <ProductAttributeMultiSelectFilter
                      name={item.name}
                      label={item.label}
                      options={item.options}
                      values={data.filters[item.id] || []}
                      onToggle={setActiveEventKey}
                      onChange={values => handleAttributeChange(item.id, values)}
                      orderingMethod="numeric"
                  />
              )}
        </div>
      )})}
    </Form>
  )
}

export default ProductFilters
