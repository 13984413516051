import React from "react"
import classNames from "classnames"
import CloseMenu from "../ProductUI/Mobile/CloseMenu"
import { Button } from "react-bootstrap"

function FilterMobileMenu({ toggleMenu, Filters, handleClearFilters }) {
  return (
    <div className="filter-sidebar">
      <div className="d-flex flex-column h-100">
        <CloseMenu toggleMenu={toggleMenu} title="Filters" />
        <div className="px-3">
          <Filters />
        </div>

        <div className={mobileFilterFooterClass}>
          <Button
            className="font-weight-500"
            variant="link"
            onClick={handleClearFilters}>
            Clear All Filters
          </Button>

          <Button variant="primary" onClick={e => toggleMenu(e)}>
            See Products
          </Button>
        </div>
      </div>
    </div>
  )
}

const mobileFilterFooterClass = classNames(
  "d-flex",
  "p-3",
  "w-100",
  "justify-content-between",
  "align-items-center",
  "mt-auto",
  "bg-grey-100"
)

export default FilterMobileMenu
