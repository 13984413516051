// extracted by mini-css-extract-plugin
export var bgGrey100 = "ProductCategoryHeader-module--bg-grey-100--4df96";
export var bgGrey150 = "ProductCategoryHeader-module--bg-grey-150--80813";
export var bgGrey200 = "ProductCategoryHeader-module--bg-grey-200--26b11";
export var bgGrey300 = "ProductCategoryHeader-module--bg-grey-300--7bad2";
export var bgGrey400 = "ProductCategoryHeader-module--bg-grey-400--a9389";
export var bgGrey500 = "ProductCategoryHeader-module--bg-grey-500--e9cae";
export var bgGrey600 = "ProductCategoryHeader-module--bg-grey-600--0fa28";
export var bgGrey700 = "ProductCategoryHeader-module--bg-grey-700--c2927";
export var bgGrey800 = "ProductCategoryHeader-module--bg-grey-800--29496";
export var bgGrey900 = "ProductCategoryHeader-module--bg-grey-900--104a7";
export var image = "ProductCategoryHeader-module--image--21387";
export var infoBannerButton = "ProductCategoryHeader-module--infoBannerButton--8befe";
export var infoBannerLeftBottomCorner = "ProductCategoryHeader-module--infoBannerLeftBottomCorner--54d15";
export var infoBannerRightSide = "ProductCategoryHeader-module--infoBannerRightSide--fb855";
export var infoBannerRightTopCorner = "ProductCategoryHeader-module--infoBannerRightTopCorner--80e6d";
export var infoBannerText = "ProductCategoryHeader-module--infoBannerText--9a70a";
export var link = "ProductCategoryHeader-module--link--c5948";
export var linkText = "ProductCategoryHeader-module--linkText--609c5";
export var shortLinkCol = "ProductCategoryHeader-module--shortLinkCol--29681";
export var shortLinkRow = "ProductCategoryHeader-module--shortLinkRow--4448b";
export var textGrey100 = "ProductCategoryHeader-module--text-grey-100--2005c";
export var textGrey150 = "ProductCategoryHeader-module--text-grey-150--2d9c3";
export var textGrey200 = "ProductCategoryHeader-module--text-grey-200--f5ef9";
export var textGrey300 = "ProductCategoryHeader-module--text-grey-300--1fd4f";
export var textGrey400 = "ProductCategoryHeader-module--text-grey-400--9d4e0";
export var textGrey500 = "ProductCategoryHeader-module--text-grey-500--6be11";
export var textGrey600 = "ProductCategoryHeader-module--text-grey-600--b6688";
export var textGrey700 = "ProductCategoryHeader-module--text-grey-700--42987";
export var textGrey800 = "ProductCategoryHeader-module--text-grey-800--49105";
export var textGrey900 = "ProductCategoryHeader-module--text-grey-900--51435";
export var threeBoxesInRowDiscount = "ProductCategoryHeader-module--threeBoxesInRowDiscount--bbb9a";
export var threeBoxesInRowLink = "ProductCategoryHeader-module--threeBoxesInRowLink--d644f";
export var threeBoxesInRowText = "ProductCategoryHeader-module--threeBoxesInRowText--11425";