import React, { useState } from "react"

import { getDynamicProductLink } from "../../../utils/productsHelper"
import Layout from "../../../components/Layout"
import ProductFilters from "../ProductFilters"
import ProductCategoryView from "./ProductCategoryView"
import { makePageSchema } from "../../../utils/seoHelper"
import {graphql} from "gatsby";

const ProductCategory = ({ data, location, pageContext }) => {

  const { name, categoryPath, history, dynamicSeo } = pageContext
  const displayProducts = JSON.parse(pageContext.displayProducts)

  const breadcrumbData = [...history, { name: name, path: categoryPath }]
  const rawSchema = pageContext?.seo?.schema?.raw
  const pageSchema = rawSchema ? makePageSchema(rawSchema) : null
  const seoHeadTags = pageContext?.seo?.fullHead

  const [productCategoryPageData, setProducCategoryPageData] = useState({
    static: true,
    filters: {},
    products: null,
    fetching: false,
    page: 0,
    sortBy: 0,
    initStaticView: false,
  })
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}>
      {children}
    </a>
  ))

  const [mobileToggle, setMobileToggle] = useState(false)

  const toggleMenu = e => {
    e.preventDefault()
    setMobileToggle(!mobileToggle)
  }

  /**
   *  Filters
   */
  const Filters = () => (
    <ProductFilters
      pageContext={pageContext}
      productData={[productCategoryPageData, setProducCategoryPageData]}
      location={location}
    />
  )

  const handleClearFilters = () => {
    setProducCategoryPageData({
      ...productCategoryPageData,
      static: true,
      filters: {},
      products: null,
      fetching: false,
      page: 0,
      sortBy: 0,
    })
  }

  const handlePageChange = page => {
    setProducCategoryPageData({ ...productCategoryPageData, static: false, page: page - 1, fetching: true })
  }

  const handleSelectSort = eventKey => {
    setProducCategoryPageData({
      ...productCategoryPageData,
      static: false,
      fetching: true,
      sortBy: Number(eventKey),
    })
  }

  return (
    <Layout
      seoHeadTags={seoHeadTags}
      schema={pageSchema}
      staticSeo={dynamicSeo}
      location={location}>
      <ProductCategoryView
        location={location}
        products={displayProducts}
        data={productCategoryPageData}
        productCategoryHeaderACFData={data.wpProductCategory.productCategoryHeader}
        pageContext={pageContext}
        breadcrumbData={breadcrumbData}
        Filters={Filters}
        mobileToggle={mobileToggle}
        toggleMenu={toggleMenu}
        handleClearFilters={handleClearFilters}
        handlePageChange={handlePageChange}
        handleSelectSort={handleSelectSort}
        CustomToggle={CustomToggle}
        getDynamicProductLink={getDynamicProductLink}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query GET_PRODUCT_CATEGORY_PAGE_DATA($pageId: String!) {
    wpProductCategory(id: { eq: $pageId }) {
      id
      name
      slug
      link
      databaseId
      uri
      productCategoryHeader {
        headerContent {
        ... on WpProductCategory_Productcategoryheader_HeaderContent_ContentFields {
            fieldGroupName
            title
            description
            shortLinks {
              label
              slug
              image {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                    quality: 75
                    width: 360
                    placeholder: NONE
                  )
                  }
                }
              }
            }
          }
        ... on WpProductCategory_Productcategoryheader_HeaderContent_InfoBanner {
            fieldGroupName
            leftSideImage {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                      formats: [AUTO, WEBP, AVIF]
                  quality: 75
                  width: 1024
                  placeholder: NONE
                )
                }
              }
            }
            rightSideInfo {
              text
              product {
              ... on WpSimpleProduct {
                ...SimpleProductQuery
                }
              ... on WpVariableProduct {
                ...VariableProductQuery
                }
              }
            }
          }
        ... on WpProductCategory_Productcategoryheader_HeaderContent_ThreeBoxesInRow {
            fieldGroupName
            boxes {
              box {
                discountCode {
                ... on WpCoupon {
                    id
                    code
                    dateExpiry
                    description
                    discountType
                    amount
                  }
                }
                icon {
                  position
                  image {
                    sourceUrl
                    title
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                            formats: [AUTO, WEBP, AVIF]
                        quality: 75
                        width: 46
                        placeholder: NONE
                      )
                      }
                    }
                  }
                }
                text
                pageLink {
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  }
  `

export default ProductCategory
