// extracted by mini-css-extract-plugin
export var bgGrey100 = "ProductAttributeSizeFilter-module--bg-grey-100--ba4cf";
export var bgGrey150 = "ProductAttributeSizeFilter-module--bg-grey-150--f6158";
export var bgGrey200 = "ProductAttributeSizeFilter-module--bg-grey-200--a7e3f";
export var bgGrey300 = "ProductAttributeSizeFilter-module--bg-grey-300--1ac8c";
export var bgGrey400 = "ProductAttributeSizeFilter-module--bg-grey-400--855a7";
export var bgGrey500 = "ProductAttributeSizeFilter-module--bg-grey-500--b9e1b";
export var bgGrey600 = "ProductAttributeSizeFilter-module--bg-grey-600--14294";
export var bgGrey700 = "ProductAttributeSizeFilter-module--bg-grey-700--80021";
export var bgGrey800 = "ProductAttributeSizeFilter-module--bg-grey-800--875f9";
export var bgGrey900 = "ProductAttributeSizeFilter-module--bg-grey-900--848a9";
export var checkbox = "ProductAttributeSizeFilter-module--checkbox--e6279";
export var textGrey100 = "ProductAttributeSizeFilter-module--text-grey-100--9c372";
export var textGrey150 = "ProductAttributeSizeFilter-module--text-grey-150--f9ffd";
export var textGrey200 = "ProductAttributeSizeFilter-module--text-grey-200--93613";
export var textGrey300 = "ProductAttributeSizeFilter-module--text-grey-300--1ca0a";
export var textGrey400 = "ProductAttributeSizeFilter-module--text-grey-400--69419";
export var textGrey500 = "ProductAttributeSizeFilter-module--text-grey-500--72c57";
export var textGrey600 = "ProductAttributeSizeFilter-module--text-grey-600--7fb09";
export var textGrey700 = "ProductAttributeSizeFilter-module--text-grey-700--43b01";
export var textGrey800 = "ProductAttributeSizeFilter-module--text-grey-800--9c426";
export var textGrey900 = "ProductAttributeSizeFilter-module--text-grey-900--c616b";