import classNames from "classnames"
import { Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col, Dropdown, Button } from "react-bootstrap"
import { v4 as uuid } from "uuid"

import { SORT_BY_ITEMS } from "../../utils/productsHelper"
import { formatMoney } from "../../utils/money"
import Paginator from "../../components/UI/Pagination/Paginator"
import DynamicPaginator from "../../components/UI/Pagination/DynamicPaginator"
import SaleFlag from "../ProductUI/SaleFlag"
import * as style from "./FilteredProductsList.module.scss"

const FilteredProductsList = ({
  data,
  products,
  location,
  toggleMenu,
  Filters,
  CustomToggle,
  getDynamicProductLink,
  handleSelectSort,
  handleClearFilters,
  handlePageChange,
  breadcrumbData,
  pageContext
}) => {
  const { totalProducts, currentPage, totalPages, categoryPath, brandPath, slugs } = pageContext

  const mobileFilterContainerClass = classNames(
    "card",
    "mt-4",
    "flex-row",
    "align-items-center",
    "w-100",
    style.filters
  )

  return (
    <Container>
      <div className="d-flex d-lg-none">
        <div className={mobileFilterContainerClass}>
          <Button variant="link" onClick={e => toggleMenu(e)}>
            Filters
            <StaticImage
              className="ml-3"
              src="../../images/icon-filter.png"
              alt="Sort icon"
              placeholder="none"
            />
          </Button>

          <div className={style.verticalLine} />

          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              Sort By
              <StaticImage
                className="ml-3"
                src="../../images/icon-sort.png"
                alt="Sort icon"
                placeholder="none"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {SORT_BY_ITEMS.map(({ title }, index) => {
                const isActive =
                  title.toLowerCase().trim() ===
                  SORT_BY_ITEMS[data.sortBy].title.toLowerCase().trim()

                return (
                  <Dropdown.Item
                    onSelect={handleSelectSort}
                    key={uuid()}
                    eventKey={index}
                    active={isActive}>
                    {title}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <Row className="py-4">
        <Col lg={3} xl={4} className="col-xxl-2 d-none d-lg-block">
          <Filters />
        </Col>

        <Col lg={9} xl={8} className="col-xxl-10">
          <div
            className={`${style.row} d-none d-lg-flex justify-content-between`}>
            <div className="d-flex align-items-center">
              <p className="h3 mr-4 mb-0">
                {data.static === true || data.products === null
                  ? totalProducts
                  : data.products.totalElements}{" "}
                Products
              </p>
              <Button
                className="py-0 font-weight-500"
                variant="link"
                onClick={handleClearFilters}>
                Clear All Filters
              </Button>
            </div>

            <div className="d-flex align-baseline">
              <p className={style.sortTitle}>Sort by</p>
              <p className={`${style.clearFilters} ml-2`}>
                <Dropdown as={"span"}>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components">
                    {SORT_BY_ITEMS[data.sortBy].title}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {SORT_BY_ITEMS.map(({ title }, index) => {
                      const isActive =
                        title.toLowerCase().trim() ===
                        SORT_BY_ITEMS[data.sortBy].title.toLowerCase().trim()

                      return (
                        <Dropdown.Item
                          onSelect={handleSelectSort}
                          key={uuid()}
                          eventKey={index}
                          active={isActive}>
                          {title}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </p>
            </div>
          </div>

          <Row className={`${style.row} no-gutters`}>
            {(data.static === true || data.products === null) &&
              products.map((item, index) => {
                let onSale, regularPrice
                if (item.nodeType === "SimpleProduct") {
                  onSale = item.onSale
                  regularPrice = item.regularPrice
                }
                if (item.nodeType === "VariableProduct") {
                  onSale = item.variations.nodes.some(item => item.onSale)
                  regularPrice = item.variations.nodes[0].regularPrice
                }

                return (
                  <Col
                    key={uuid()}
                    md={6}
                    className={`col-xxl-4 justify-content-center ${style.singleCol}`}>
                    <Link
                      className={style.productLink}
                      to={getDynamicProductLink(slugs, item)}
                      state={{ breadcrumbData: breadcrumbData }}>
                      <div className={style.productTile} key={index}>
                        {item?.featuredImage && (
                          <GatsbyImage
                            className={style.productImg}
                            image={getImage(item.featuredImage.node?.localFile)}
                            alt={item.featuredImage.node?.altText}
                          />
                        )}
                        {item?.image && (
                          <GatsbyImage
                            className={style.productImg}
                            image={getImage(item.image.localFile)}
                            alt={item.image.altText}
                          />
                        )}

                        {onSale && <SaleFlag />}
                        <div className={style.productNameWrapper}>
                          <h2 className={style.productName}>{item.name}</h2>
                          {onSale ? (
                            <div className="d-flex justify-content-center">
                              <p className={`${style.salePrice}  text-center`}>
                                {item.minPrice === item.maxPrice
                                  ? formatMoney(item.minPrice)
                                  : `${formatMoney(
                                      item.minPrice
                                    )} - ${formatMoney(item.maxPrice)}`}
                              </p>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-center">
                              <p className={style.text}>
                                {regularPrice}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                  </Col>
                )
              })}

            {data.static === false &&
              data.products !== null &&
              data.products.data.map((item, index) => {
                return (
                  <Col
                    key={uuid()}
                    md={6}
                    className={`col-xxl-4 justify-content-center ${style.singleCol}`}>
                    <Link
                      className={style.productLink}
                      to={getDynamicProductLink(slugs, item)}>
                      <div className={style.productTile} key={index}>
                        {item.imageSrc && (
                          <div className={style.productImg}>
                            <img
                              src={item.imageSrc}
                              alt={item.imageAlt}
                              style={{
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                objectPosition: "center center",
                                opacity: 1,
                                transition: "none 0s ease 0s",
                              }}
                            />
                          </div>
                        )}

                        {item.onSale && <SaleFlag />}
                        <div className={style.productNameWrapper}>
                          <h2 className={style.productName}>{item.name}</h2>
                          {item.onSale && (
                            <div className="d-flex justify-content-center">
                              <p
                                className={`${style.salePrice} mx-3 text-center`}>
                                {item.minPrice === item.maxPrice
                                  ? formatMoney(item.price)
                                  : `${formatMoney(
                                      item.minPrice
                                    )} - ${formatMoney(item.maxPrice)}`}
                              </p>
                            </div>
                          )}
                          {!item.onSale && (
                            <div className="d-flex justify-content-center">
                              <p className={`${style.text}  text-center`}>
                                {formatMoney(item.price)}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                  </Col>
                )
              })}
            <div
              className={`${style.helpBox} d-flex flex-column align-items-center justify-content-center`}>
              <p className={style.helpText}>Need help to choose ?</p>
              <div className="w-100 h6">
                {categoryPath && categoryPath.includes("electric-bikes") ? (
                  <Link to={"/buyers-guide"}>Read the Buyers Guide</Link>
                ) : (<div className="d-flex flex-column align-items-center w-100">
                  <div className="mb-2">Give us a call:</div>
                  <div className="mb-2">Shepherds Bush – <a href={`tel:${process.env.MAIN_PHONE_NUMBER}`}>{process.env.MAIN_PHONE_NUMBER}</a></div>
                  <a href={process.env.WHATSAPP_API_URL}>Whatsapp</a>
                  </div>)}
              </div>
            </div>
          </Row>
          <Row>
            {(data.static === true || data.products === null) && (
              <Paginator
                className={"w-100 my-4"}
                currentPage={currentPage}
                totalPages={totalPages}
                path={categoryPath || brandPath}
                location={location}
              />
            )}
            {data.static === false && data.products !== null && (
              <DynamicPaginator
                className={"w-100 my-4"}
                currentPage={data.page + 1}
                totalPages={data.products.totalPages}
                onChange={handlePageChange}
              />
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
export default FilteredProductsList
