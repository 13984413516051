import React from 'react'
import CloseIcon from "../../../images/icon-close.inline.svg"
import { Link } from 'gatsby';


function CloseMenu({ toggleMenu, title }) {
    return (
      <div className="mb-3 p-3 bg-primary">
        <div className="d-flex">
          <Link className="close-icon" to="#" onClick={e => toggleMenu(e)}>
            <CloseIcon />
          </Link>
          <p className="text-secondary flex-grow-1 text-center mb-0">{title}</p>
        </div>

      </div>
    )
}


export default CloseMenu;

