import React, { Component } from "react"
import { Link } from "gatsby"
import * as PropTypes from "prop-types"
import * as style from './Paginator.module.scss'

class Paginator extends Component {
  render() {
    const {currentPage,totalPages, className, path} = this.props;
    if(totalPages<2){
      return <div/>;
    }

    const pages = [];
    for(let i=1;i<=totalPages;i++){
      if(i===currentPage) {
        pages.push(<div key={i} className={i === currentPage ? style.activeItem : style.item}>{i}</div>)
      } else {
        pages.push(<Link key={i} to={i===1 ? path : path + `/page-${i}`} className={i === currentPage ? style.activeItem : style.item}>{i}</Link>)
      }
    }

    return (
      <div className={className}>
      <div className={`${style.container} pagination flex-wrap`}>
        {pages}
      </div>
      </div>
    )
  }
}

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  className:PropTypes.string,
  path: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
}

export default Paginator
