import React, { Component } from "react"
import * as PropTypes from "prop-types"
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Accordion from "../Accordion"
import { Button } from "react-bootstrap"
import ArrowDown from "../../../images/icon-arrow-dropdown-icon-dropdown-down.inline.svg"
import {debounce} from 'lodash/function'



class ProductPriceFilter extends Component {

  //Samples
  //http://react-component.github.io/slider/?path=/story/rc-slider--marks

  render() {
    const {min,max,activeEventKey, eventKey,onToggle, label, onChange, currentValue} = this.props;
    const minValue = Math.floor(min);
    const maxValue = Math.ceil(max);

    const createSliderWithTooltip = Slider.createSliderWithTooltip;
    const Range = createSliderWithTooltip(Slider.Range);

    const marks = {};

    marks[minValue] = <strong>{minValue}</strong>;
    marks[maxValue] = <strong>{maxValue}</strong>;

    if(minValue ===maxValue){
      return <></>
    }

    const debounceOnChange = debounce(onChange, 1000);

    return (
      <>
        <Accordion
          title={label}
        />

      <Accordion activeEventKey={activeEventKey} onToggle={onToggle}>

        <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
          <div className={`d-flex justify-content-between align-items-baseline`}>
            <h3 className="text-uppercase mt-3 mb-4">{label}</h3>
            <ArrowDown aria-label="chevron-toggle" className={activeEventKey === eventKey ? "rotate-r-180" : ""} />

          </div>
        </Accordion.Toggle>

        <Accordion.Collapse eventKey={eventKey}>
          <div className="mb-5">
            <Range step={1} min={minValue} max={maxValue} defaultValue={currentValue} allowCross={false} onChange={(data) => debounceOnChange(data)}
                   marks={marks}
                   railStyle={{ backgroundColor: '#6b787b'}}
                   handleStyle={[{borderColor: 'black',boxShadow: 'none'},{borderColor: 'black', boxShadow: 'none'}]}
                   trackStyle={[{backgroundColor: 'black'},{backgroundColor: 'black'}]}
                   />
          </div>
        </Accordion.Collapse>
      </Accordion>

    </>
    )
  }
}

ProductPriceFilter.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  activeEventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onToggle: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  currentValue: PropTypes.array.isRequired
}

export default ProductPriceFilter
