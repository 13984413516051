import React, { Component } from "react"
import * as PropTypes from "prop-types"
import * as style from './DynamicPaginator.module.scss'

class DynamicPaginator extends Component {

  handleOnClick = page => {
    if(this.props.onChange){
      this.props.onChange(page);
    }
  }

  render() {
    const {currentPage,totalPages, className} = this.props;
    if(totalPages<2){
      return <div/>;
    }

    const pages = [];
    for(let i=1;i<=totalPages;i++){
      if(i===currentPage) {
        pages.push(<div key={i} className={i === currentPage ? style.activeItem : style.item}>{i}</div>)
      } else {
        pages.push(<a key={i} onClick={()=> this.handleOnClick(i)} className={i === currentPage ? style.activeItem : style.item}>{i}</a>)
      }
    }

    return (
      <div className={className}>
        <div className={style.container}>
          {pages}
        </div>
      </div>
    )
  }
}

DynamicPaginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  className:PropTypes.string,
  onChange: PropTypes.func
}

export default DynamicPaginator
