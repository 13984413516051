import React, { useState } from "react"
import * as PropTypes from "prop-types"
import { Collapse } from "react-bootstrap"
import * as style from "./ProductSubcategoriesFilter.module.scss"
import ArrowDown from "../../../images/icon-arrow-dropdown-icon-dropdown-down.inline.svg"
import classNames from "classnames"
import { Link } from "gatsby"

function ProductSubcategoriesFilter({ pageContext, label, subcategories }) {

  const [open, setOpen] = useState(true);

  if(!subcategories || (subcategories.length === 0)){
    return <div/>
  }

  return (
    <>
      <a
        className={collapseHeaderContainerClass}
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
        <div className={collapseHeaderClass}>
          <h3 className="text-uppercase">
            {label}
          </h3>
          <ArrowDown aria-label="chevron-toggle" className={open ? "rotate-r-180" : ""} />
        </div>

      </a>

      <Collapse in={open}>
        <div id="example-collapse-text">
          <ul className={listClass}>
                           {
                   subcategories.map(({ name, slug }, i) => {
                     return (
                       <li key={i}>
                         <Link className={style.link} to={`${pageContext.categoryPath}/${slug}`}>{name}</Link>
                       </li>
                     )
                   })
                 }
          </ul>
        </div>
      </Collapse>
      <hr className="d-lg-none" />
    </>
  )
}

const collapseHeaderContainerClass = classNames("d-block", "mt-2", "mb-2", "mt-lg-5", "mt-mb-4", "pointer", "text-no-underline");
const collapseHeaderClass = classNames("d-flex", "justify-content-between", "align-items-baseline", "mb-0");
const listClass = classNames("mb-1", "list-unstyled");

ProductSubcategoriesFilter.propTypes = {
  //activeEventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  //eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onToggle: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  subcategories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  })).isRequired
}

export default ProductSubcategoriesFilter
