import React, { useState, useEffect } from "react"
import * as PropTypes from "prop-types"
import * as style from "./ProductAttributeMultiSelectFilter.module.scss"
import { Collapse } from "react-bootstrap"
import { Form } from "react-bootstrap"
import ArrowDown from "../../../images/icon-arrow-dropdown-icon-dropdown-down.inline.svg"
import classNames from "classnames"

function ProductAttributeMultiSelectFilter({
  label,
  name,
  options,
  values,
  onChange,
  orderingMethod,
}) {
  if (label === "Brand" && process.env.SITE_ID.startsWith("RG")) {
    let list = process.env.PRODUCT_MAKE_LIST.split(",")
    label = "Make"
    options = options.filter(option => list.includes(option))
  }
  if (orderingMethod && orderingMethod === "numeric") {
    options.sort(function(a, b) {
      return a - b;
    });
  }
  const isSearchEnabled = options.length > 7
  const [open, setOpen] = useState(true)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [searchParam, setSearchParam] = useState("")
  const [searchResults, setSearchResults] = React.useState([])
  const dataMapping = isSearchEnabled ? searchResults : options

  useEffect(() => {
    setSelectedOptions(values)
    if (isSearchEnabled) {
      const res = options.filter(option =>
        option.toLowerCase().includes(searchParam)
      )
      setSearchResults(res)
    }
  }, [values, searchParam])

  const onSelect = e => {
    let newOptions

    if (e.target.checked) {
      newOptions = [...selectedOptions, e.target.value]
    } else {
      newOptions = selectedOptions.filter((x, i) => x !== e.target.value)
    }

    if (onChange) {
      onChange(newOptions)
    }

    setSelectedOptions(newOptions)
  }

  const handleChange = event => {
    setSearchParam(event.target.value)
  }

  return (
    <>
      {options.length > 0 && (
        <>
          <a
            className={collapseHeaderContainerClass}
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}>
            <div className={collapseHeaderClass}>
              <h3 className="text-uppercase">{label}</h3>
              <ArrowDown
                aria-label="chevron-toggle"
                className={open ? "rotate-r-180" : ""}
              />
            </div>
            {/* Mobile only */
            selectedOptions.length > 0 && !open && (
              <p className="text-greyed mb-0 d-lg-none">
                {selectedOptions.map((x, i) => (
                  <span key={i}>
                    {x} {selectedOptions.length - 1 !== i && ", "}
                  </span>
                ))}
              </p>
            )}
          </a>

          <Collapse in={open}>
            <div id="example-collapse-text">
              {isSearchEnabled && (
                <input
                  placeholder="Type to search"
                  type="search"
                  className={style.searchBox}
                  onChange={e => handleChange(e)}
                  value={searchParam}
                />
              )}
              <ul className={listClass}>
                {dataMapping.map((option, i) => (
                  <li key={i} className={style.checkbox}>
                    <Form.Check
                      onChange={e => onSelect(e)}
                      type="checkbox"
                      id={`${name}_${i}`}
                      checked={selectedOptions.includes(option)}
                      label={option}
                      value={option}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </Collapse>
          <hr className="d-lg-none" />
        </>
      )}
    </>
  )
}

ProductAttributeMultiSelectFilter.propTypes = {
  //activeEventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  //eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onToggle: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
}

const collapseHeaderContainerClass = classNames(
  "d-block",
  "mt-2",
  "mb-2",
  "mt-lg-3",
  "mt-mb-4",
  "pointer",
  "text-no-underline"
)
const collapseHeaderClass = classNames(
  "d-flex",
  "justify-content-between",
  "align-items-baseline",
  "mb-0"
)
const listClass = classNames("mb-1", "list-unstyled", style.list)

export default ProductAttributeMultiSelectFilter
