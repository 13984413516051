import React from "react"

import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb"
import ProductCategoryHeader from "./ProductCategoryHeader"

import FilterMobileMenu from "../FilterMobileMenu"
import FilteredProductsList from "../FilteredProductsList"

const ProductCategoryView = ({
  location,
  products,
  data,
  productCategoryHeaderACFData,
  pageContext,
  breadcrumbData,
  Filters,
  mobileToggle,
  toggleMenu,
  handleClearFilters,
  handlePageChange,
  handleSelectSort,
  CustomToggle,
  getDynamicProductLink,
}) => {
  return (
    <>
      {mobileToggle && (
        <FilterMobileMenu
          Filters={Filters}
          toggleMenu={toggleMenu}
          handleClearFilters={handleClearFilters}
        />
      )}

      <Breadcrumb data={breadcrumbData} />

      <ProductCategoryHeader h1={true} headerData={productCategoryHeaderACFData} />

      <FilteredProductsList
        location={location}
        data={data}
        breadcrumbData={breadcrumbData}
        pageContext={pageContext}
        products={products}
        Filters={Filters}
        toggleMenu={toggleMenu}
        CustomToggle={CustomToggle}
        handleSelectSort={handleSelectSort}
        handleClearFilters={handleClearFilters}
        handlePageChange={handlePageChange}
        getDynamicProductLink={getDynamicProductLink}
      />
    </>
  )
}

export default ProductCategoryView
