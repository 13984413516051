import React, { useState, useEffect } from "react"
import * as PropTypes from "prop-types"
import { Collapse } from "react-bootstrap"
import * as style from "./ProductAttributeColorFilter.module.scss"
import ArrowDown from "../../../images/icon-arrow-dropdown-icon-dropdown-down.inline.svg"
import classNames from "classnames"

function ProductAttributeColorFilter({ label, options, colours, values, onChange }) {

  const [open, setOpen] = useState(true)
  const [selectedItems, setSelectedItems] = useState(values || [])

  useEffect(() => {
    setSelectedItems(values);
  }, [values])

  const handleClick = option => {

    let values = [...selectedItems]

    if (selectedItems.includes(option)) {
      const index = values.indexOf(option)
      if (index > -1) {
        values.splice(index, 1)
      }
    } else {
      values.push(option)
    }


    if(onChange) {
      onChange(values);
    }

    setSelectedItems(values)

  }

  return (
    <>
      <a
        className={collapseHeaderContainerClass}
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
        <div className={collapseHeaderClass}>
          <h3 className="text-uppercase">
            {label}
          </h3>
          <ArrowDown aria-label="chevron-toggle" className={open ? "rotate-r-180" : ""} />
        </div>

      </a>

      <Collapse in={open}>
        <div id="example-collapse-text">
          <ul className={listClass}>
            {
              options.map((option, i) =>
                (
                  <li key={i} className="list-inline-item">
                    <div
                         className={selectedItems.includes(option) ? style.activeColorItemWrapper : style.colorItemWrapper}>
                      <a className={style.colorItem} style={{ backgroundColor: `${colours[option]}` }}
                         onClick={() => handleClick(option)} />
                    </div>
                  </li>
                ),
              )
            }
          </ul>
        </div>
      </Collapse>
      <hr className="d-lg-none" />
    </>
  )
}


const collapseHeaderContainerClass = classNames("d-block", "mt-2", "mb-2", "mt-lg-5", "mt-mb-4", "pointer", "text-no-underline")
const collapseHeaderClass = classNames("d-flex", "justify-content-between", "align-items-baseline", "mb-0")
const listClass = classNames("mb-1", "list-unstyled")

ProductAttributeColorFilter.propTypes = {
  //activeEventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  //eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onToggle: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  colours: PropTypes.object.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
}

export default ProductAttributeColorFilter
