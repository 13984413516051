import React, { useState, useEffect } from "react"
import * as PropTypes from "prop-types"
import { Collapse, Form } from "react-bootstrap"
import * as style from "./ProductAttributeSizeFilter.module.scss"
import ArrowDown from "../../../images/icon-arrow-dropdown-icon-dropdown-down.inline.svg"
import classNames from "classnames"

const standardSizes = {
  XS: 0,
  S: 1,
  M: 2,
  L: 3,
  XL: 4,
  XXL: 5,
  XXXL: 6,
  XXXXL: 7,
  XXXXXL: 8,
}

function getSizeValue(size) {
  if (standardSizes[size] !== undefined) {
    return standardSizes[size]
  } else if (size.includes("-")) {
    // combined size
    let sizes = size.split("-")
    return (getSizeValue(sizes[0]) + getSizeValue(sizes[1])) / 2
  } else {
    // irregular size
    return Infinity
  }
}

function compareSizes(a, b) {
  return getSizeValue(a) - getSizeValue(b)
}

function ProductAttributeSizeFilter({
  label,
  name,
  options,
  values,
  onChange,
}) {
  const [open, setOpen] = useState(true)
  const [selectedOptions, setSelectedOptions] = useState(values || [])

  useEffect(() => {
    setSelectedOptions(values)
  }, [values])

  const onSelect = e => {
    let newOptions

    if (e.target.checked) {
      newOptions = [...selectedOptions, e.target.value]
    } else {
      newOptions = selectedOptions.filter((x, i) => x !== e.target.value)
    }

    if (onChange) {
      onChange(newOptions)
    }

    setSelectedOptions(newOptions)
  }

  return (
    <>
      <a
        className={collapseHeaderContainerClass}
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}>
        <div className={collapseHeaderClass}>
          <h3 className="text-uppercase">{label}</h3>
          <ArrowDown
            aria-label="chevron-toggle"
            className={open ? "rotate-r-180" : ""}
          />
        </div>
        {
          /* Mobile only */
          selectedOptions.length > 0 && !open &&
          <p className="text-greyed mb-0 d-lg-none">
            {
              selectedOptions.map((x, i) => <span key={i}>{x} {selectedOptions.length - 1 !== i && ', '}</span>)
            }
          </p>
        }
      </a>

      <Collapse in={open}>
        <div id="example-collapse-text">
          <ul className={listClass}>
            {options.sort(compareSizes).map((option, i) => (
              <li className={style.checkbox} key={i}>
                <Form.Check
                  onChange={e => onSelect(e)}
                  type="checkbox"
                  id={`${name}_${i}`}
                  label={option}
                  value={option}
                  checked={selectedOptions.includes(option)}
                />
              </li>
            ))}
          </ul>
        </div>
      </Collapse>
      <hr className="d-lg-none" />
    </>
  )
}

const collapseHeaderContainerClass = classNames(
  "d-block",
  "mt-2",
  "mb-2",
  "mt-lg-3",
  "mt-mb-4",
  "pointer",
  "text-no-underline"
)
const collapseHeaderClass = classNames(
  "d-flex",
  "justify-content-between",
  "align-items-baseline",
  "mb-0"
)
const listClass = classNames("mb-1", "list-unstyled")

ProductAttributeSizeFilter.propTypes = {
  //activeEventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  //eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onToggle: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
}

export default ProductAttributeSizeFilter
